import React, { useEffect } from "react"
import { navigate, Link } from "gatsby"
import DocumentHeadMeta from '@components/utility/SEO.jsx'
import { isEDMode, getNested } from '@src/helper';

// Style Imports
import './styles.scss'
// Structural Imports
import Layout from "@components/layout/layout.jsx"
// import Image from "@components/content/fractals-image.jsx"

const ResubscribeConfirmed = ({location}) => {

    if (isEDMode) {
        location.state = {
            values: {
                email: '[name@domain.com]',
                unsubscribeAuriniaAlliance: true,
                unsubscribeTreatment: true,
                unsubscribeCorporate: true
            } 
        }
    }

    useEffect(()=>{
        //gatsby isn't mapping location.state correctly on build so we need to do a nested check to see if email exists if not we redirect

        if(!isEDMode && !getNested(location,'state', 'values', 'email')) {
            
            navigate('/register');
        }
    },[])


    return (
        <Layout addedClass="page--resubscribe-confirmed">
            <DocumentHeadMeta title='Resubscribe Confirmation | ALL IN&reg; for Lupus Nephritis' description='' robots="noindex" canonicalURL='https://www.allinforlupusnephritis.com/unsubscribe/resubscribe' />
            <section className='bg-white'>
                <div className="container container--narrow subscribe-confirmation-container">
                    <h1 className="heading heading--accent">Welcome back!</h1>
                    <h2>We're happy to see you again</h2>
                    <p>You have been successfully resubscribed.</p>
                    <Link to="/" className="cta--purple cta--body cta--body--wide">Visit the ALL IN Home Page</Link>
                </div>
            </section>
        </Layout>
    )
}

export default ResubscribeConfirmed